import { Emitter } from '@leyan/emitter';
import type { Logger, LoggerLabels, LoggerMeta } from './types';
import { appendParams, combineMessage, formatLabels, formatName } from './utils';
import type LoggerManager from './LoggerManager';
import NoopLogger from './NoopLogger';

type ProxyLoggerEventsMap = {
  logging(meta: LoggerMeta): void;
};

export interface ProxyLoggerOptions {
  name?: string;
  labels?: LoggerLabels;
  logger?: Logger;
  parent?: ProxyLogger;
  manager?: LoggerManager;
}

class ProxyLogger extends NoopLogger implements Logger {
  readonly name: string;

  readonly manager?: LoggerManager;

  private _labels: LoggerLabels;

  private _parent?: ProxyLogger;

  private _logger?: Logger;

  private _emitter?: Emitter<ProxyLoggerEventsMap>;

  constructor(options: ProxyLoggerOptions = {}) {
    const { name = '', labels = {}, logger, parent, manager } = options;

    super(
      ({
        name = this.name,
        level = 'info',
        message = '',
        params = [],
        labels = this.getLabels(),
      }) => {
        const meta: LoggerMeta = { name, level, message, params, labels };

        this._emitter?.emit('logging', meta);

        if (this._logger) {
          if (this._logger instanceof NoopLogger) {
            this._logger.log(meta);
          } else {
            this._logger[level](
              combineMessage(formatName(name), message),
              ...appendParams(params, formatLabels(labels)),
            );
          }
        } else {
          const proxyLogger = this._parent ?? this.manager?.getLogger();

          if (proxyLogger && proxyLogger !== this) {
            proxyLogger.log(meta);
          }
        }
      },
    );

    this.name = name;
    this.manager = manager;
    this._labels = { ...labels };
    this._logger = logger;
    this._parent = parent;
  }

  extend(name: string, delimiter = '/') {
    const childName = this.name ? `${this.name}${delimiter}${name}` : name;

    if (this.manager) {
      const proxyLogger = this.manager.getLogger(childName);

      proxyLogger.setParent(this);

      return proxyLogger;
    }

    return new ProxyLogger({
      name: childName,
      parent: this,
    });
  }

  getParent() {
    return this._parent;
  }

  setParent(parent?: ProxyLogger) {
    this._parent = parent;

    return this;
  }

  getLogger() {
    return this._logger;
  }

  setLogger(logger?: Logger) {
    this._logger = logger;

    return this;
  }

  onLogging(handle: (meta: LoggerMeta) => void) {
    let emitter = this._emitter;

    if (!emitter) {
      emitter = new Emitter();

      this._emitter = emitter;
    }

    return emitter.on('logging', handle);
  }

  getLabels() {
    return this._labels;
  }

  setLabels(labels: LoggerLabels): this;

  setLabels(updater: (labels: LoggerLabels) => LoggerLabels): this;

  setLabels(maybeLabels: any) {
    const labels = typeof maybeLabels === 'function' ? maybeLabels(this._labels) : maybeLabels;

    this._labels = labels;

    return this;
  }
}

export default ProxyLogger;
