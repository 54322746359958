class BufferQueue<T> {
  readonly buffer: number;

  readonly overflow: number;

  private _store: T[] = [];

  constructor(buffer: number = 1000, overflow: number = 0) {
    this.buffer = buffer;
    this.overflow = overflow;
  }

  get size() {
    return this._store.length;
  }

  isEmpty() {
    return this._store.length === 0;
  }

  empty() {
    return this._store.splice(0, this._store.length);
  }

  dequeue(size: number = 1) {
    return this._store.splice(0, size);
  }

  enqueue(...items: T[]) {
    const overflow = this._store.push(...items) - this.buffer;

    if (overflow > this.overflow) {
      return this._store.splice(0, overflow);
    }

    return [];
  }
}

export default BufferQueue;
