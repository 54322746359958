import { now } from '@leyan/rate-limiter';

class Timer {
  private _isRunning: boolean = false;

  private _duration: number = 0;

  private _startAt?: number;

  private _stopAt?: number;

  isRunning() {
    return this._isRunning;
  }

  start() {
    this._isRunning = true;
    this._startAt = now(true);
    this._stopAt = undefined;
    this._duration = 0;

    return this;
  }

  stop() {
    if (this.isRunning()) {
      this._stopAt = now(true);
      this._duration = this._stopAt - this._startAt!;
      this._isRunning = false;
    }

    return this;
  }

  reset() {
    if (this.isRunning()) {
      this._startAt = undefined;
      this._stopAt = undefined;
      this._duration = 0;
      this._isRunning = false;
    }

    return this;
  }

  startAt(highPrecision: boolean = false) {
    if (highPrecision || this._startAt === undefined) {
      return this._startAt;
    }

    return Math.ceil(this._startAt);
  }

  stopAt(highPrecision: boolean = false) {
    if (highPrecision || this._stopAt === undefined) {
      return this._stopAt;
    }

    return Math.ceil(this._stopAt);
  }

  duration(highPrecision: boolean = false) {
    const duration = this.isRunning() ? now(true) - this._startAt! : this._duration;

    if (highPrecision) {
      return duration;
    }

    return Math.ceil(duration);
  }
}

export default Timer;
