import { now, wait } from './utils';

/**
 * 检查每秒产生令牌数是否合法
 * @param permitsPerSecond 每秒产生令牌数
 * @returns
 */
function checkPermitsPerSecond(permitsPerSecond: number) {
  if (!Number.isFinite(permitsPerSecond) || permitsPerSecond <= 0) {
    throw new TypeError(`permitsPerSecond (${permitsPerSecond}) must be positive number.`);
  }
}

/**
 * 检查最大存储令牌数是否合法
 * @param maxPermits 最大存储令牌数
 * @returns
 */
function checkMaxPermits(maxPermits: number) {
  if (!Number.isFinite(maxPermits) || maxPermits <= 0) {
    throw new TypeError(`maxPermits (${maxPermits}) must be positive number.`);
  }
}

/**
 * 检查令牌数是否合法
 * @param permits 令牌数
 * @returns
 */
function checkPermits(permits: number) {
  if (!Number.isInteger(permits) || permits <= 0) {
    throw new TypeError(`permits (${permits}) must be positive integer.`);
  }
}

/**
 * 检查过期时间是否合法
 * @param timeout 过期时间(毫秒)
 * @returns
 */
function checkTimeout(timeout: number) {
  if (!Number.isFinite(timeout)) {
    throw new TypeError(`timeout (${timeout}) must be finite number.`);
  }
}

/**
 * 限流器
 */
class RateLimiter {
  /**
   * 每秒产生令牌数
   */
  private _permitsPerSecond: number;

  /**
   * 最大存储令牌数
   */
  private _maxPermits: number;

  /**
   * 当前存储令牌数
   */
  private _storedPermits: number;

  /**
   * 下次可生成令牌时间点 (毫秒)
   */
  private _nextFreeTicketTime: number;

  /**
   * 单个令牌产生间隔时间 (毫秒)
   */
  private _stableIntervalTime: number;

  /**
   * 构造限流器
   * @param permitsPerSecond 每秒产生令牌数
   * @param maxPermits 最大存储令牌数，默认值等于每秒产生令牌数
   */
  constructor(permitsPerSecond: number, maxPermits: number = permitsPerSecond) {
    checkPermitsPerSecond(permitsPerSecond);
    checkMaxPermits(maxPermits);

    this._permitsPerSecond = permitsPerSecond;
    this._maxPermits = maxPermits;
    this._storedPermits = 0;
    this._nextFreeTicketTime = now(true);
    this._stableIntervalTime = 1000 / permitsPerSecond;
  }

  /**
   * 刷新令牌数
   * @param now 当前时间点(毫秒)
   * @returns
   */
  private _resync(now: number) {
    if (now > this._nextFreeTicketTime) {
      const newPermits = (now - this._nextFreeTicketTime) / this._stableIntervalTime;
      this._storedPermits = Math.min(this._maxPermits, this._storedPermits + newPermits);
      this._nextFreeTicketTime = now;
    }

    return this;
  }

  /**
   * 预留令牌供应获取
   * @param now 当前时间(毫秒)
   * @param permits 获取令牌数
   * @returns 等待时间(毫秒)
   */
  private _reserve(now: number, permits: number) {
    this._resync(now);

    const waitTime = Math.max(this._nextFreeTicketTime - now, 0);
    const storedPermitsToSpend = Math.min(permits, this._storedPermits);
    const freshPermits = permits - storedPermitsToSpend;

    this._nextFreeTicketTime += freshPermits * this._stableIntervalTime;
    this._storedPermits -= storedPermitsToSpend;

    return waitTime;
  }

  /**
   * 判断在过期时间内是否可以获得令牌
   * @param now 当前时间点(毫秒)
   * @param timeout 超时时间(毫秒)
   * @returns 是否可以获取令牌
   */
  private _canAcquire(now: number, timeout: number) {
    return this._nextFreeTicketTime - timeout <= now;
  }

  /**
   * 获取每秒产生令牌数
   * @returns 每秒产生令牌数
   */
  getPermitsPerSecond() {
    return this._permitsPerSecond;
  }

  /**
   * 设置每秒产生令牌数
   * @param permitsPerSecond 每秒产生令牌数
   * @returns
   */
  setPermitsPerSecond(permitsPerSecond: number) {
    checkPermitsPerSecond(permitsPerSecond);

    this._resync(now(true));

    this._permitsPerSecond = permitsPerSecond;
    this._stableIntervalTime = 1000 / permitsPerSecond;

    return this;
  }

  /**
   * 获取最大存储令牌数
   * @returns 最大存储令牌数
   */
  getMaxPermits() {
    return this._maxPermits;
  }

  /**
   * 设置最大存储令牌数
   * @param maxPermits 最大存储令牌数
   * @returns
   */
  setMaxPermits(maxPermits: number) {
    checkMaxPermits(maxPermits);

    this._storedPermits *= maxPermits / this._maxPermits;
    this._maxPermits = maxPermits;

    return this;
  }

  /**
   * 获取令牌
   * @param permits 令牌数
   * @returns
   */
  async acquire(permits: number) {
    checkPermits(permits);

    const waitTime = this._reserve(now(true), permits);

    await wait(waitTime);
  }

  /**
   * 尝试在过期时间内获取令牌
   * @param permits 令牌数
   * @param timeout 过期时间 (毫秒)，默认值为 0 (毫秒)
   * @returns 令牌是否获取成功
   */
  async tryAcquire(permits: number, timeout: number = 0) {
    checkPermits(permits);
    checkTimeout(timeout);

    const nowTime = now(true);

    if (!this._canAcquire(nowTime, Math.max(timeout, 0))) {
      return false;
    }

    const waitTime = this._reserve(nowTime, permits);

    await wait(waitTime);

    return true;
  }
}

export default RateLimiter;
