export type Onceable<T extends (...args: any[]) => any> = {
  (...args: Parameters<T>): ReturnType<T>;
  readonly origin: T;
  readonly invoked: boolean;
  readonly result: ReturnType<T> | undefined;
};

export function onceable<T extends (...args: any[]) => any>(
  fn: T,
  callback?: (result: ReturnType<T>) => void,
): Onceable<T> {
  const wrapper = (...args: Parameters<T>) => {
    if (!wrapper.invoked) {
      wrapper.result = fn(...args);
      wrapper.invoked = true;

      if (callback) {
        callback(wrapper.result as ReturnType<T>);
      }
    }

    return wrapper.result as ReturnType<T>;
  };

  wrapper.origin = fn;
  wrapper.invoked = false;
  wrapper.result = undefined as ReturnType<T> | undefined;

  return wrapper;
}

export function isOnceable(target: any): target is Onceable<any> {
  const candidate = target as Onceable<any>;

  return (
    typeof candidate === 'function' &&
    typeof candidate.origin === 'function' &&
    typeof candidate.invoked === 'boolean'
  );
}

export function disposable(fn: () => void) {
  let disposed = false;

  const dispose = () => {
    if (!disposed) {
      fn();

      disposed = true;

      return true;
    }

    return false;
  };

  return dispose;
}
