import { useEffect, useCallback } from 'react';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector';
import type { StringKeyOf, Get } from 'type-fest';

import { KeyOfValue, Attributes, GrowthBookFactory, GrowthBook } from '@leyan/growthbook';

export type { KeyOfValue, Attributes };

export { GrowthBookFactory, GrowthBook };

export function createUseFeatureValue<KV extends KeyOfValue, A extends Attributes>(
  growthBookFactory: GrowthBookFactory<KV, A>,
) {
  function useFeatureValue<FK extends StringKeyOf<KV>>(
    featureKey: FK,
    defaultValue: KV[FK],
    isEqual: (a: KV[FK], b: KV[FK]) => boolean,
  ): KV[FK];

  function useFeatureValue<FK extends StringKeyOf<KV>>(
    featureKey: FK,
    defaultValue: KV[FK],
  ): KV[FK];

  function useFeatureValue<FK extends StringKeyOf<KV>>(featureKey: FK): KV[FK];

  function useFeatureValue<FK extends StringKeyOf<KV>>(
    featureKey: FK,
    defaultValue?: KV[FK],
    isEqual?: (a: KV[FK], b: KV[FK]) => boolean,
  ): KV[FK] {
    const growthBook = growthBookFactory.getGrowthBook(featureKey);

    useEffect(() => {
      growthBook.refresh();
    }, []);

    const getSnapshot = () => {
      return growthBook.getFeatureValue(defaultValue!);
    };

    return useSyncExternalStoreWithSelector<KV[FK], KV[FK]>(
      useCallback((callback) => {
        return growthBook.onChange(callback);
      }, []),
      getSnapshot,
      undefined,
      (s) => s,
      isEqual,
    );
  }

  return useFeatureValue;
}

export function createGetFeatureValue<KV extends KeyOfValue, A extends Attributes>(
  growthBookFactory: GrowthBookFactory<KV, A>,
) {
  function getFeatureValue<FK extends StringKeyOf<KV>>(featureKey: FK): KV[FK];

  function getFeatureValue<FK extends StringKeyOf<KV>, Path extends string>(
    featureKey: FK,
    path: Path,
  ): Get<KV[FK], Path>;

  function getFeatureValue<FK extends StringKeyOf<KV>, Path extends string>(
    featureKey: FK,
    path?: Path,
  ): KV[FK] | Get<KV[FK], Path> {
    const growthBook = growthBookFactory.getGrowthBook(featureKey);

    if (path) {
      return growthBook.get(path);
    }

    return growthBook.getFeatureValue();
  }

  return getFeatureValue;
}
