import { now, RateLimiter } from '@leyan/rate-limiter';
import { create, XAdapter, XRequestInstance } from '@leyan/x-request';
import type {
  LitoEvent,
  LitoEventLabels,
  LitoEventName,
  LitoEventParams,
  LitoEventRaw,
  LitoLabels,
  LitoMiddleware,
} from './types';
import { compose, createDeviceid, createSessionid, noop, stringifyLabels } from './utils';
import BufferQueue from './BufferQueue';

export interface LitoOptions {
  appname: string;
  appkey: string;
  version: string;
  deviceid?: string;
  sessionid?: string;
  labels?: LitoLabels;
  buffer?: number;
  batch?: number;
  qps?: number;
  timeout?: number;
  endpoint?: string;
  adapter?: XAdapter;
  middlewares?: LitoMiddleware[];
  onDiscard?(events: LitoEvent[]): void;
  onFlush?(events: LitoEvent[]): void;
  onError?(error: unknown, events: LitoEvent[]): void;
}

class Lito<TEventLabels extends LitoEventLabels = LitoEventLabels> {
  private _labels: LitoLabels = {};

  private _middlewares: LitoMiddleware[] = [];

  private _handleDiscard: (events: LitoEvent[]) => void = noop;

  private _handleFlush: (events: LitoEvent[]) => void = noop;

  private _handleError: (error: unknown, events: LitoEvent[]) => void = noop;

  private _queue: BufferQueue<LitoEvent> = new BufferQueue(1000, 50);

  private _ready: boolean = false;

  private _alive: boolean = true;

  private _batch?: number;

  private _rateLimiter?: InstanceType<typeof RateLimiter>;

  private _request?: XRequestInstance;

  private _flushing?: Promise<boolean>;

  private _collect(events: LitoEvent[]) {
    return this._request!<
      { data: string },
      { query: { send_timestamp: number }; data: LitoEventRaw[] }
    >('POST /events', {
      query: {
        send_timestamp: now(),
      },
      data: events.map((event) => {
        return {
          ...event,
          labels: stringifyLabels(event.labels),
        };
      }),
    });
  }

  private async _tryFlush(): Promise<boolean> {
    let result = false;

    if (!this._queue.isEmpty()) {
      await this._rateLimiter!.acquire(1);

      if (!this._queue.isEmpty()) {
        const events = this._queue.dequeue(this._batch!);

        if (events.length > 0) {
          try {
            await this._collect(events);

            this._handleFlush(events);

            result = true;
          } catch (error) {
            this._handleError(error, events);
          }
        }
      }

      if (!this._queue.isEmpty()) {
        return this._tryFlush();
      }
    }

    return result;
  }

  get size() {
    return this._queue.size;
  }

  get request() {
    return this._request;
  }

  init(options: LitoOptions) {
    const {
      endpoint,
      appname,
      appkey,
      version,
      deviceid = createDeviceid(),
      sessionid = createSessionid(),
      labels = {},
      buffer = 1000,
      batch = 50,
      qps = 1,
      timeout = 3000,
      adapter,
      middlewares = [],
      onDiscard = noop,
      onFlush = noop,
      onError = noop,
    } = options;

    const events = this._queue.empty();

    this._batch = batch;
    this._labels = { ...labels };
    this._middlewares = [...middlewares];
    this._handleDiscard = onDiscard;
    this._handleFlush = onFlush;
    this._handleError = onError;
    this._rateLimiter = new RateLimiter(qps);
    this._request = create({
      adapter,
      timeout,
      baseURL: endpoint,
      query: {
        appname,
        appkey,
        version,
        deviceid,
        sessionid,
      },
    });

    if (this._queue.buffer !== buffer || this._queue.overflow !== batch) {
      this._queue = new BufferQueue(buffer, batch);
    }

    this._ready = true;
    this._alive = true;

    if (events.length > 0) {
      for (const event of events) {
        this.capture(event as LitoEventParams<TEventLabels>, true);
      }

      this.flush();
    }

    return this;
  }

  getLabels() {
    return this._labels;
  }

  setLabels(labels: LitoLabels): this;

  setLabels(updater: (labels: LitoLabels) => LitoLabels): this;

  setLabels(maybeLabels: any) {
    const labels = typeof maybeLabels === 'function' ? maybeLabels(this._labels) : maybeLabels;

    this._labels = labels;

    return this;
  }

  isReady() {
    return this._ready;
  }

  isAlive() {
    return this._alive;
  }

  isFlushing() {
    return Boolean(this._flushing);
  }

  async flush() {
    if (!this._ready) {
      return false;
    }

    if (!this._flushing) {
      this._flushing = this._tryFlush().finally(() => {
        this._flushing = undefined;
      });
    }

    return this._flushing;
  }

  capture<TName extends LitoEventName<TEventLabels>>(
    params: LitoEventParams<TEventLabels, TName>,
    lazy?: boolean,
  ) {
    const { name, value = 1, labels = {} as LitoLabels, timestamp = now() } = params;

    const event: LitoEvent = {
      name,
      value,
      labels: {
        ...this._labels,
        ...labels,
      },
      timestamp,
    };

    const execute = compose(...this._middlewares);

    execute(event, () => {
      if (this._alive) {
        const events = this._queue.enqueue(event);

        if (events.length > 0) {
          this._handleDiscard(events);
        }

        if (!lazy) {
          this.flush();
        }
      } else {
        this._handleDiscard([event]);
      }
    });

    return this;
  }

  async shutdown() {
    this._alive = false;

    await this.flush();
  }
}

export default Lito;
