import axios from 'axios';
import qs from 'qs';
import get from 'lodash/get';
import debug from 'debug';
import equal from 'fast-deep-equal';
import type { Get } from 'type-fest';

export type AttributeValue = string | number | boolean | string[] | number[];

export type Attributes = Record<string, AttributeValue>;

export type GetAttributes<A extends Attributes> = () => A;

export type FeatureKey = string;

export type OnSuccess<FK, V> = (
  featureKey: FK,
  featureResult: FeatureResult<V>,
  successCount: number,
  cached: boolean,
) => void;

export type OnError<FK> = (featureKey: FK, err: Error, errorCount: number) => void;

export interface Context<
  A extends Attributes,
  V extends FeatureResultValue,
  FK extends FeatureKey,
> {
  apiHost: string;
  clientKey: string;
  appName: string;
  appVersion: string;
  featureKey: FK;
  defaultValue: V;
  environment: 'development' | 'production';
  attributes?: A | GetAttributes<A>;
  refreshInterval?: number;
  ttl?: number;
  onSuccess?: OnSuccess<FK, V>;
  onError?: OnError<FK>;
}

export type FeatureResultValue = string | number | boolean | Record<string, unknown>;

export type FeatureResultSource =
  | 'unknownEnvironment'
  | 'unknownFeature'
  | 'defaultValue'
  | 'force'
  | 'override'
  | 'experiment';

export interface FeatureResult<V = FeatureResultValue> {
  value: V | null;
  source: FeatureResultSource;
  on: boolean;
  off: boolean;
  ruleId: string;
  revisionId: string;
}

export interface ResponseData<V> {
  feature: FeatureResult<V>;
  cacheKey?: string;
}

export type WatchKey = string;

export type WatchMethod<V = any> = (newValue: V, oldValue: V) => void;

export type Unwatch = () => void;

export type Unbind = () => void;

export const DEFAULT_FEATURE_RESULT: FeatureResult<null> = {
  value: null,
  on: false,
  off: true,
  source: 'unknownFeature',
  ruleId: '',
  revisionId: '',
};

export const MIN_REFRESH_INTERVAL: number = 3 * 1000;

export const PRIVATE_KEYS = ['_authorization', '_cacheKey'];

export function checkPrivateKeys<A extends Attributes>(attrs?: A | GetAttributes<A>) {
  const attributes = typeof attrs === 'function' ? attrs() : attrs;

  if (attributes) {
    for (const key of PRIVATE_KEYS) {
      if (typeof attributes[key] !== 'undefined') {
        throw new Error(`${key} 是系统使用字段，不能作为自定义属性。`);
      }
    }
  }
}

export const stringify = (attributes?: Attributes): string => {
  if (!attributes) {
    return '';
  }

  return qs.stringify(attributes);
};

const logger = {
  debug: debug('growthbook:debug'),
  info: debug('growthbook:info'),
  error: debug('growthbook:error'),
};

export class GrowthBook<V extends FeatureResultValue, A extends Attributes, FK extends FeatureKey> {
  protected lastFetched: number = 0;

  protected fetchCount: number = 0;

  protected successCount: number = 0;

  protected errorCount: number = 0;

  protected context!: Context<A, V, FK>;

  protected previousFeatureResult?: FeatureResult<V>;

  protected currentFeatureResult?: FeatureResult<V>;

  protected refreshTimer?: number;

  protected cacheKey?: string;

  protected watchMapping = new Map<WatchKey, WatchMethod[]>();

  protected watchMethods: WatchMethod<V>[] = [];

  constructor(context: Context<A, V, FK>) {
    this.setContext(context);
  }

  setContext(context: Context<A, V, FK>) {
    this.parseContext(context);
  }

  protected parseContext(context: Context<A, V, FK>) {
    this.context = context;

    this.setAttributes(context.attributes);

    this.setRefreshInterval(context.refreshInterval);
  }

  getContext() {
    return this.context;
  }

  setAttributes(attributes?: A | GetAttributes<A>) {
    checkPrivateKeys(attributes);

    this.context.attributes = attributes;
  }

  setRefreshInterval(refreshInterval?: number) {
    if (refreshInterval !== undefined && refreshInterval < MIN_REFRESH_INTERVAL) {
      throw new Error(`refreshInterval 值不能小于 ${MIN_REFRESH_INTERVAL}`);
    }

    this.context.refreshInterval = refreshInterval;
  }

  protected getRefreshInterval() {
    return this.context.refreshInterval;
  }

  async start() {
    return this.startRefresh();
  }

  stop() {
    this.clearRefresh();
  }

  protected startRefresh = async () => {
    this.clearRefresh();

    const featureResult = await this.fetchFeature();

    const refreshInterval = this.getRefreshInterval();

    if (refreshInterval) {
      this.refreshTimer = setTimeout(this.startRefresh, refreshInterval);
    }

    return featureResult;
  };

  async refresh() {
    return this.fetchFeature();
  }

  protected clearRefresh() {
    const { refreshTimer } = this;

    if (refreshTimer) {
      clearTimeout(refreshTimer);
    }
  }

  protected getEndpointUrl() {
    const { apiHost, featureKey, environment } = this.context;

    return `${apiHost}/features/${featureKey}/${environment}`;
  }

  protected getRequestUrl() {
    const { context, cacheKey } = this;

    const { attributes: attrs, appName, appVersion, clientKey } = context;

    const contextAttributes = typeof attrs === 'function' ? attrs() : attrs;

    const attributes: Attributes = {
      ...contextAttributes,
      appName,
      appVersion,
      _authorization: clientKey,
    };

    if (cacheKey) {
      attributes._cacheKey = cacheKey;
    }

    const endpointUrl = this.getEndpointUrl();

    const queryString = stringify(attributes);

    let requestUrl = endpointUrl;

    if (queryString) {
      requestUrl = `${endpointUrl}?${queryString}`;
    }

    return requestUrl;
  }

  // eslint-disable-next-line class-methods-use-this
  protected getRequestHeaders() {
    const headers: Record<string, string> = {
      Accept: 'application/json',
    };

    return headers;
  }

  protected isExpired() {
    const { ttl } = this.context;

    if (typeof ttl === 'undefined') {
      return true;
    }

    const interval = Date.now() - this.lastFetched;

    return interval > ttl;
  }

  protected fetchFeature = async (): Promise<FeatureResult<V | null>> => {
    if (!this.isExpired()) {
      return this.getFeatureResult();
    }

    this.lastFetched = Date.now();
    this.fetchCount += 1;

    const { fetchCount } = this;

    const url = this.getRequestUrl();
    const headers = this.getRequestHeaders();

    logger.debug('%s fetchFeature Request: %O', this.getContextInfo(), {
      url,
      headers,
      fetchCount,
    });

    return axios
      .get<ResponseData<V>>(url, {
        headers,
        validateStatus(status) {
          return status === 200 || status === 304;
        },
      })
      .then((response) => {
        this.successCount += 1;

        if (this.fetchCount !== fetchCount) {
          return this.getFeatureResult();
        }

        const { headers, data, status } = response;
        const { feature: featureResult, cacheKey } = data;

        logger.debug('%s fetchFeature Response: %O', this.getContextInfo(), {
          status,
          headers,
          featureResult,
          cacheKey,
        });

        if (cacheKey) {
          this.cacheKey = cacheKey;
        }

        if (this.currentFeatureResult) {
          this.previousFeatureResult = this.currentFeatureResult;
        }

        const { onSuccess, featureKey } = this.context;
        const { successCount } = this;
        const hasOnSuccess = typeof onSuccess === 'function';

        if (status === 200) {
          this.currentFeatureResult = featureResult;

          this._onChange();

          if (hasOnSuccess) {
            onSuccess(featureKey, this.currentFeatureResult!, successCount, false);
          }

          return this.currentFeatureResult;
        }

        if (status === 304) {
          this._onNotChange();

          if (hasOnSuccess) {
            onSuccess(featureKey, this.previousFeatureResult!, successCount, true);
          }

          return this.previousFeatureResult!;
        }

        return featureResult;
      })

      .catch((err) => {
        this.errorCount += 1;

        this._onError(err);

        const { onError, featureKey } = this.context;
        const { errorCount } = this;

        if (typeof onError === 'function') {
          onError(featureKey, err, errorCount);
        }

        return this.getFeatureResult();
      });
  };

  protected getFeatureResult() {
    return this.currentFeatureResult || this.previousFeatureResult || DEFAULT_FEATURE_RESULT;
  }

  getFeatureValue(defaultValue: V): V;

  getFeatureValue(): V;

  getFeatureValue(defaultValue?: V): V {
    const { value } = this.getFeatureResult();

    if (value === null) {
      if (typeof defaultValue !== 'undefined') {
        return defaultValue;
      }

      return this.context.defaultValue;
    }

    return value;
  }

  get<Path extends string>(path: Path, defaultValue: Get<V, Path>): Get<V, Path>;

  get<Path extends string>(path: Path): Get<V, Path>;

  get<Path extends string>(path: Path, defaultValue?: Get<V, Path>): Get<V, Path> {
    const value = this.getFeatureValue();

    return get(value, path, defaultValue) as Get<V, Path>;
  }

  watch<Key extends WatchKey>(key: Key, method: WatchMethod<Get<V, Key>>): Unwatch {
    let watchMethods = this.watchMapping.get(key);

    if (watchMethods) {
      watchMethods.push(method);
    } else {
      watchMethods = [method];

      this.watchMapping.set(key, watchMethods);
    }

    return () => {
      const index = watchMethods!.findIndex((watchMethod) => watchMethod === method);

      watchMethods!.splice(index, 1);
    };
  }

  onChange(method: WatchMethod<V>): Unbind {
    this.watchMethods.push(method);

    return () => {
      const index = this.watchMethods.findIndex((watchMethod) => watchMethod === method);

      this.watchMethods.splice(index, 1);
    };
  }

  protected getContextInfo() {
    return `[GrowthBook:${this.context.featureKey}] [${new Date().toLocaleString()}]`;
  }

  protected _onChange = () => {
    const { previousFeatureResult, currentFeatureResult } = this;

    if (previousFeatureResult && currentFeatureResult) {
      const currentValue = currentFeatureResult.value;
      const previousValue = previousFeatureResult.value;

      for (const [watchKey, watchMethods] of this.watchMapping.entries()) {
        const newValue = get(currentValue, watchKey);
        const oldValue = get(previousValue, watchKey);
        const isEquals = equal(newValue, oldValue);

        if (!isEquals) {
          for (const watchMethod of watchMethods) {
            try {
              watchMethod(newValue, oldValue);
            } catch (err) {
              logger.error(
                '%s Execute WatchMethod get error',
                this.getContextInfo(),
                err,
                watchKey,
                newValue,
                oldValue,
              );

              throw err;
            }
          }
        }
      }

      for (const watchMethod of this.watchMethods) {
        try {
          watchMethod(currentValue!, previousValue!);
        } catch (err) {
          logger.error(
            '%s Execute WatchMethod get error',
            this.getContextInfo(),
            err,
            currentValue,
            previousValue,
          );

          throw err;
        }
      }
    }
  };

  protected _onNotChange = () => {
    logger.info(
      '%s _onNotChange() => fetchCount: %d, successCount: %d, errorCount: %d',
      this.getContextInfo(),
      this.fetchCount,
      this.successCount,
      this.errorCount,
    );
  };

  protected _onError = (err: any) => {
    logger.error(
      '%s _onError() => fetchCount: %d, successCount: %d, errorCount: %d, %O',
      this.getContextInfo(),
      this.fetchCount,
      this.successCount,
      this.errorCount,
      err,
    );
  };
}
