import type { Logger, LoggerMeta, LogLevel } from './types';

class NoopLogger implements Logger {
  private _write?: (meta: Partial<LoggerMeta>) => void;

  constructor(write?: (meta: Partial<LoggerMeta>) => void) {
    this._write = write;
  }

  log(meta: Partial<LoggerMeta>): void;

  log(level: LogLevel, message?: any, ...optionalParams: any[]): void;

  log(maybeLevel: any, maybeMessage?: any, ...optionalParams: any[]) {
    if (this._write) {
      const meta: Partial<LoggerMeta> =
        typeof maybeLevel === 'object'
          ? maybeLevel
          : {
              level: maybeLevel,
              ...(typeof maybeMessage === 'string'
                ? {
                    message: maybeMessage,
                    params: optionalParams,
                  }
                : {
                    message: '',
                    params:
                      optionalParams.length > 0 || maybeMessage !== undefined
                        ? [maybeMessage, ...optionalParams]
                        : [],
                  }),
            };

      this._write(meta);
    }
  }

  silly(message?: any, ...optionalParams: any[]) {
    this.log('silly', message, ...optionalParams);
  }

  debug(message?: any, ...optionalParams: any[]) {
    this.log('debug', message, ...optionalParams);
  }

  verbose(message?: any, ...optionalParams: any[]) {
    this.log('verbose', message, ...optionalParams);
  }

  info(message?: any, ...optionalParams: any[]) {
    this.log('info', message, ...optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]) {
    this.log('warn', message, ...optionalParams);
  }

  error(message?: any, ...optionalParams: any[]) {
    this.log('error', message, ...optionalParams);
  }
}

export default NoopLogger;
